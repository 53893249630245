import { navigate } from "gatsby-link";
import React, { useState } from "react";
import { useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import { useRegion, useRegions } from "../components/Region";
import Seo from "../components/seo";
import { WatchNowBody } from "../pages/watch-now";

export default function WatchNowInfluencer({
  pageContext: { id, name, playerLink, regions },
}: {
  pageContext: InfluencerLink;
}) {
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null);
  const regionIds = regions.map(({ id }) => id);
  const commonTranslations = useCommonTranslations();

  const redirectTimeout = () =>
    setTimeout(() => {
      navigate("/watch-now");
    }, 1000);

  if (isValidRegion(regionIds) && timeoutHandler) {
    clearTimeout(timeoutHandler);
    setTimeoutHandler(null);
  }

  if (!isValidRegion(regionIds)) {
    if (!timeoutHandler) setTimeoutHandler(redirectTimeout());
    return null;
  }

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.watchNow} />
      <WatchNowBody pantaflixPlayerUrl={playerLink} />
    </Layout>
  );
}

const isValidRegion = (ids: number[]) => {
  const region = useRegion();

  const [, id] = region.id.split("_");

  return ids.includes(Number(id));
};
